export default {
  "home": {
    
  },
  "order": {
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le panier"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "deliveryfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
    "eatin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur place"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emporter"])},
    "ordertable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande à table"])},
    "quanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité "])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "choise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez maintenant"])},
    "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans"])},
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrédients"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites votre choix"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement à réception"])},
    "cb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de crédit"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une table"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenant"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié"])},
    "whene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À quel moment souhaitez-vous recevoir votre commande:"])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme voulez-vous payer:"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "Deliveryfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indisponible"])},
    "whichday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel jour:"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "takeawayAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander à emporter"])},
    "deliveryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livraison à domicile"])},
    "eatinAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander sur place"])},
    "ordertableAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande à table"])},
    "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de produit"])},
    "paidInTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer la table"])}
  },
  "cac": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un point de collecte."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer"])}
  },
  "scan": {
    "again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désirez passer une nouvelle commande, veuillez rescannez le code QR."])}
  },
  "confirm": {
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande confirmée"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu procéder au paiement."])},
    "tryagain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])}
  },
  "customer": {
    "stayintouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données non enregistrées et à usage unique"])},
    "deliveryInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse 2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postale"])}
  },
  "message": {
    "lastnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de renseigner votre nom"])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de renseigner votre e-mail"])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter, Votre commande est en cours de traitement."])},
    "orderAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez passé votre commande avec succès"])},
    "orderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOPS Quelque chose ne va pas, nous n'avons pas pu passer votre commande"])},
    "customerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOPS Quelque chose ne va pas, nous n'avons pas pu ajouter vos infomations"])},
    "itemAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'article a été ajouté au panier avec succès !"])},
    "missingSuppliments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir toutes les suppléments "])},
    "notavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit n'est pas disponible"])},
    "unvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur, adressez-vous à un responsable."])},
    "ERROR_CAISSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])},
    "ERROR_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])},
    "FAILED_LOADING_XML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])},
    "TABLE_OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])},
    "TABLE_OCCUPIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])},
    "CONNECTION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Veuillez vérifier avec votre restaurant"])}
  },
  "error": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés, mais la page que vous recherchez n'existe pas. "])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez notre APP"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En téléchargeant notre application, vous pouvez facilement commander et rester informé."])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
  },
  "suggestion": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ça peut vous intéresser."])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci"])}
  },
  "categories": {
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, les produits de cette catégorie ne sont pas disponibles actuellement."])}
  },
  "restaurant": {
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les commandes sont désactivées jusqu'à"])}
  },
  "day": {
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])}
  }
}