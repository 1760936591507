import request from '../../../api/httpClient';
export default {
  state: {
    productItems: []
  },
  mutations: {
    UPDATE_PRODUCTS_LIST(state: any, payload: any) {
      state.productItems = payload;
    },


  },
  actions: {
    getProductsList({ commit }: any, idRestaurant: number) {
      request.httpClient().get('productsAtPlace/' + idRestaurant).then((response) => {
        commit('UPDATE_PRODUCTS_LIST', response.data.categories)
      });
    },
    getProductsCACList({ commit }: any, idRestaurant: number) {
      request.httpClient().get('productsCAC/' + idRestaurant).then((response) => {
        commit('UPDATE_PRODUCTS_LIST', response.data.categories)
      });
    },

    clearProductList({ commit }: any) {
      commit('UPDATE_PRODUCTS_LIST', [])
    }
  },
  getters: {
    productList: (state: any) => state.productItems,

  }

}
